<template>
  <div id="wrapper" class="legal">
    <!-- page content -->
    <div id="page-wrapper">

      <!-- router content -->
      <div class="wrapper-content">
        <img class="logo" alt="image" src="@/assets/images/prologistics_logo.png"/>

        <b-button @click.prevent="$closeWindow()" variant="no-outline" style="float: right;">
          <font-awesome-icon icon="times"/>
        </b-button>

        <router-view/>
      </div>

    </div>

    <!-- footer -->
    <div class="footer">
      <b-row>

        <b-col md order="1" order-md="0">
          <img class="footer-logo" alt="prologistics logo" src="@/assets/images/prologistics_logo.png"> | {{ $t("prologistics.slogan") }} &copy; {{ year }}
        </b-col>

        <b-col md order="0" order-md="1">
          <shared-links/>
        </b-col>

      </b-row>
    </div>

  </div>
</template>

<script>

import SharedLinks from "@/components/shared_links.vue";

export default {
  name: "Legal",
  components: {SharedLinks},
  data() {
    return {}
  },
  computed: {
    year() {
      return new Date().getFullYear()
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
#wrapper {
  all: inherit;
}

#page-wrapper {
  all: inherit;
}

.wrapper-content {
  all: inherit;
  margin: 60px;
}

.footer {
  width: 100%;
  margin: unset;
  &-logo {
    width: 80px;
  }
}

.logo {
  max-width: 200px;
}

h1, h2, h3 {
  text-transform: uppercase;
  font-weight: 600;
}

ol {
  padding-left: 30px;
}

p, li {
  line-height: 1.7em;
}
</style>
